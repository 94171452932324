import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const ArrowLink = styled.a`
  color: #5e8a64;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;

  position: relative;
  padding-right: 40px;
  cursor: pointer;
  text-decoration: none;

  &::after {
    content: url("../images/arrow.svg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: right ease 0.4s;
  }

  &:hover {
    opacity: 0.8;
    &::after {
      right: -10px;
    }
  }
  &:active {
    opacity: 0.5;
  }
`;


const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        When people become overwhelmed by life challenges and past experiences, their lives can be shattered. My mission, since completing my masters in counseling from Arizona State University in 2015, is to provide a safe space for people to heal, process root causes, and find insight to begin a journey of reclaiming their lives. I help adult clients discover the roadmap to defeating anxiety, depression, and trauma, to feel competent, capable, and resilient once more.    </p>
        <p>
        I operate from a Cognitive Behavioral Therapy lens to understand clients, and have been trained in the following modalities: Cognitive Behavioral Therapy, Person Centered Approach, Emotion Focused Therapy, Motivational Interviewing, and Mindfulness. In treatment, I’m client focused so clients can learn to become empowered. Counseling has the capacity to help clients create healthy change, and it is a privilege for me to help facilitate the spark of hope that clients, then cultivate into conquering whatever issues they face.        </p>
        <p>
          <ArrowLink target="_blank" href="http://jhartleycounseling.com">Visit My Website</ArrowLink>      
        </p>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
