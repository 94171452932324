import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        Rob was born, adopted, and raised in Upstate South Carolina, where he learned to love
everything college football, bluegrass music, and deep fried. Shortly after high school, Rob
enlisted in the United States Army where he served as an Information Systems Specialist (25B)
in an air defense artillery unit. Upon leaving the military, Rob returned to his hometown for a few
years before moving to Oregon in 2014 with his spouse, who was raised here in Oregon. Rob
graduated from Umpqua Community College where he served in the college’s student union,
then attended University of Oregon from where he graduated with honors with a bachelor’s
degree in General Social Sciences with a concentration in Crime, Law, and Society. From UO,
Rob took a stroll across Alder Street to Bushnell University, where he earned a master’s degree
in Clinical Mental Health Counseling, also with departmental honors
</p>
<p>
As an autistic adult, Rob sees the world through a different lens than some other folks,
and this gives him a unique approach to psychotherapy. In addition, autism allows Rob to have
a deeper understanding and appreciation for neurodivergent clients. As a veteran, Rob has
plenty of experience in working with other veterans and their unique experiences. As an
adoptee, Rob understands the struggles of abandonment and identity exploration that comes
with being adopted. All of these intersecting experiences bring a unique perspective to Rob’s
clinical approach. Rob employs a blend of narrative, existential, and client-centered therapeutic
interventions, relying heavily on Lenihanian dialectics to help clients make sense of their
experiences and find healing and peace.

</p>
<p>
Concerning hobbies, Rob is an enthusiastic Tolkiendil, in proud possession of an
extensive library of books and collections by Tolkien. Rob’s love of Tolkien has influenced his
love of tabletop roleplaying games such as Dungeons and Dragons as well as the worldbuilding
game The Quiet Year. Rob is an enthusiastic soccer fan, supporting Liverpool F.C., Portland
Timbers/Thorns, and Charleston Battery. Rob is an avid runner, having run multiple events from
5Ks to marathons. Rob also enjoys video games, with city builders and grand strategies such as
Cities: Skylines and Civilization, respectively, being particular favorites.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
