import React from 'react';
import { useProgress } from "../../contexts/ProgressContext";
import styled from "styled-components";
import ButtonPlain from "../ButtonPlain";
import IntakeQuestionnaire from "./IntakeQuestionnaire"; // Replace with the actual next step component

const ArrowLink = styled.a`
  color: #5e8a64;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;

  position: relative;
  padding-right: 40px;
  cursor: pointer;
  text-decoration: none;

  &::after {
    content: url("../images/arrow.svg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: right ease 0.4s;
  }

  &:hover {
    opacity: 0.8;
    &::after {
      right: -10px;
    }
  }
  &:active {
    opacity: 0.5;
  }
`;

const OutOfPocketDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 700px) {
    padding-bottom: 50px;
  }

  @media screen and (max-height: 780px) and (min-width: 700px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .price {
    color: #5e8a64;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    margin: 20px auto;
  }

  .information {
    width: 716px;
    max-width: 100%;
    margin: 20px auto 40px;
  }

  button {
    margin-top: 20px;
    width: 240px;
  }
`;

const OutOfPocketDetailsKansas = () => {
  const { nextStep } = useProgress();

  const continueClicked = () => {
    nextStep(<IntakeQuestionnaire />, 90);
  };

  return (
    <OutOfPocketDetailsWrap>
      <h2>Out of Pocket</h2>
      <p className="price">We accept $175 per hour<br/> or $150 per hour prepaid for 6 sessions.</p>
      <p className="information">
        To be seen immediately, you can prepay for 1 session in advance for $175.<br/><ArrowLink target="_blank" href="https://buy.stripe.com/bIY5o74Lkel94Gk4gi">Purchase 1 Session Now</ArrowLink><br></br><br></br>
        Or, you can prepay for 6 sessions in advance for $900.<br/><ArrowLink target="_blank" href="https://buy.stripe.com/fZe4k3dhQ6SH0q4fZ1">Purchase 6 Sessions Now</ArrowLink>
      </p>
      <ButtonPlain className="continue-button" dark onClick={continueClicked}>
        Continue
      </ButtonPlain>
    </OutOfPocketDetailsWrap>
  );
};

export default OutOfPocketDetailsKansas;
