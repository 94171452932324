import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <h3>Counseling available in English and Farsi.</h3>
        <p>
        Hello, and welcome. My name is Hoda. I completed my M.S. degree in Couples and Family Therapy from the University of Oregon in 2018.  
        </p>
        <p>
        Life may feel overwhelming, confusing, stuck, and/or challenging at times. My aim is to provide a collaborative, supportive, and judgement-free environment where you can learn more about yourself and feel accepted and supported throughout your therapeutic journey. My goal is to create a space for you where healing and growth can be cultivated.  I believe in exploring your unique stories, life experiences, strengths, and transitions. While we may talk about difficult things, I also aspire to bring patience, empathy, a sense of humor, warmth, and care into our sessions.  
        </p>
        <p>
        I enjoy working with individuals from diverse backgrounds, including BIPOC, bicultural, multicultural, and immigrant communities. I am dedicated to helping you find your unique expression by exploring your cultural identity and celebrating the intersectionality of identities you hold. 
        </p>
        <p>
        I utilize a variety of clinical approaches in sessions, and tailor these to each client’s needs, goals, and preferences. I often incorporate the following therapeutic modalities: Collaborative Therapy, Person Centered Therapy, Attachment Therapy, Mindfulness Techniques, Motivational Interviewing, Narrative Therapy, Internal Family Systems (IFS), and Cognitive Behavioral Therapy (CBT). 
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
