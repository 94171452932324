import React, { useEffect, useState } from "react";
import { useProgress } from "../../contexts/ProgressContext";
import styled from "styled-components";
import ButtonPlain from "../ButtonPlain";
import Select from "../Select";
import PaymentUtah from "./PaymentUtah"; // Make sure to replace with the actual component
import PaymentOregon from "./PaymentOregon"; // Make sure to replace with the actual component
import PaymentKansas from "./PaymentKansas"; // Make sure to replace with the actual component

const LocationWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    text-align: center;
  }

  .location-selection {
    display: flex;
    gap: 20px;
    margin: 20px;

    @media screen and (max-width: 800px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin: 30px 0 10px;
    }
  }
  select {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc; 
    border-radius: 4px; 
  }
  .county-list {
    list-style: none;
    padding: 0;
    background-color: #e6f4ea; 
    border-radius: 4px; 
    width: 200px; 
    overflow: hidden; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  }
  .county-item {
    padding: 10px 15px;
    border-bottom: 1px solid #dcdcdc;
    cursor: pointer;
    &:hover {
      background-color: #cdecd0;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  button {
    width: 240px;
  }

  .buttonUtah {
    font-weight: bold; 
    padding: 10px 20px;
    border-radius: 5px; 
    &:hover {
      background-color: #f2f2f2; 
    }
    background: url("/images/locations/utah-counties.jpg") no-repeat;
    background-size: 200px;
    width: 200px;
    height: 200px;
    font-size: 30px;
  }

  .buttonKansas {
    font-weight: bold; 
    padding: 10px 20px;
    border-radius: 5px; 
    &:hover {
      background-color: #f2f2f2; 
    }
    background: url("/images/locations/kansas-counties.jpg") no-repeat;
    background-size: 200px;
    width: 200px;
    height: 200px;
    font-size: 30px;
  }

  .buttonOregon {
    font-weight: bold; 
    padding: 10px 20px;
    border-radius: 5px; 
    &:hover {
      background-color: #f2f2f2; 
    }
    background: url("/images/locations/oregon-counties.jpg") no-repeat;
    background-size: 200px;
    width: 200px;
    height: 200px;
    font-size: 30px;
  }
`;

const Location = () => {
  const { nextStep, setLocation } = useProgress();
  const [county, setCounty] = useState("Oregon"); // State to hold the selected county

  const kansasClicked = () => {
    setLocation("kansas");
    nextStep(<PaymentKansas />, 40);
  };

  const utahClicked = () => {
    setLocation("utah");
    nextStep(<PaymentUtah />, 40);
  };

  const oregonClicked = () => {
    nextStep(<PaymentOregon />, 40);
  };

  const handleCountyChange = (newValue) => {
    setCounty(newValue);
    setLocation(newValue);
    oregonClicked();
  };

  return (
    <LocationWrap>
      <h2>Where are you living?</h2>
      <div className="location-selection">
        <ButtonPlain className="buttonKansas" onClick={kansasClicked} dark>
          Kansas
        </ButtonPlain>
      </div>
    </LocationWrap>
  );
};

export default Location;
