import React from "react";
import { useProgress } from "../../contexts/ProgressContext";
import styled from "styled-components";
import ButtonPlain from "../ButtonPlain";
import IntakeQuestionnaire from "./IntakeQuestionnaire"; // Replace with the actual next step component

const MedicaidDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 700px) {
    padding-bottom: 50px;
  }

  @media screen and (max-height: 780px) and (min-width: 700px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .green {
    color: #5e8a64;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  .providers-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    margin-top: 20px;
    font-size: 16px;
    width: 980px;
    max-width: 100%;
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  }

  .provider {
    padding: 20px;
    font-size: 16px;
    line-height: 1.5;
  }

  button {
    margin-top: 20px;
    width: 240px;
  }
`;

const MedicareUtahDetails = () => {
  const { nextStep } = useProgress();

  const continueClicked = () => {
    nextStep(<IntakeQuestionnaire />, 90);
  };

  return (
    <MedicaidDetailsWrap>
      <h2>Medicare</h2>
      <p className="green">
        we are licensed with these insurance providers:
      </p>
      <div className="providers-grid">
      <div className="provider">
          <b>Select Health</b>
          <br />
          All Counties
        </div>
        <div className="provider">
          <b>Aetna</b>
          <br />
          All Counties
        </div>
        <div className="provider">
          <b>United Healthcare</b>
          <br />
          All Counties
        </div>
        <div className="provider">
        <b>Regence Blue Cross Blue Shield</b>
          <br />
          All Counties
        </div>
        <div className="provider">
        <b>Motiv Health</b>
          <br />
          All Counties
        </div>
        <div className="provider">
        <b>Sana Benefits</b>
          <br />
          All Counties
        </div>
        <div className="provider">
        <b>EMI Health</b>
          <br />
          All Counties
        </div>
        <div className="provider">
        <b>PEHP Health & Benefits</b>
          <br />
          All Counties
        </div>
        <div className="provider">
        <b>PEHP Health & Benefits</b>
          <br />
          All Counties
        </div>
      </div>
      <ButtonPlain dark onClick={continueClicked}>
        Continue
      </ButtonPlain>
    </MedicaidDetailsWrap>
  );
};

export default MedicareUtahDetails;
