import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const ArrowLink = styled.a`
  color: #5e8a64;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;

  position: relative;
  padding-right: 40px;
  cursor: pointer;
  text-decoration: none;

  &::after {
    content: url("../images/arrow.svg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: right ease 0.4s;
  }

  &:hover {
    opacity: 0.8;
    &::after {
      right: -10px;
    }
  }
  &:active {
    opacity: 0.5;
  }
`;

const Content = () => {
  return (
    <>
      <Wrap>
        <p>
        <ArrowLink target="_blank" href="https://care.tavahealth.com/providers/lorraine-simmons?source=client">Schedule a Session</ArrowLink>      
        </p>
        <h2>Introduction</h2>
        <p>
        Therapy is a powerful tool for fostering empowerment, self-compassion, and personal growth. I am committed to listening to your unique concerns without judgment and supporting you on your path to optimal wellness. Together, we will enhance your ability to set and strive for goals, build and nurture healthy relationships, and reconnect with yourself.
        </p>
       
        <p>
        Therapy is a powerful tool for fostering empowerment, self-compassion, and personal growth. I am committed to listening to your unique concerns without judgment and supporting you on your path to optimal wellness. Together, we will enhance your ability to set and strive for goals, build and nurture healthy relationships, and reconnect with yourself.
        I am a Certified Trauma Professional. I also have certifications in EMDR, Grief Care, Acceptance and Commitment Therapy (ACT), Trauma-Focused CBT, Mindfulness, DBT, and IFS. I specialize in supporting adults and teens dealing with depression, anxiety, mood disorders, trauma and abuse, and Grief and Loss.        
        </p>

        <p>
        I believe the therapeutic relationship is fundamental to the success of therapy, and I strive to create a trusting, safe environment where clients feel comfortable discussing their needs and overcoming their challenges. Together, we can work toward healing and growth.
        </p>
        <p>
        <ArrowLink target="_blank" href="https://care.tavahealth.com/providers/lorraine-simmons?source=client">Start Your Journey Today</ArrowLink>      
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
